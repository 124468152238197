import useMediaQuery from "../hooks/useMediaQuery";
import { motion, useSpring } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import SocialMediaIcons from "../components/SocialMediaIcons";

const Landing = ({ setSelectedPage }) => {
  const isAboveSmallScreens = useMediaQuery("(min-width: 780px)");
  console.log(isAboveSmallScreens);

  return (
    <section
      id="home"
      className="flex flex-col justify-between items-center max-h-full gap-16 py-10"
    >
      {isAboveSmallScreens ? (
        <>
          <video
            id="v0"
            className="absolute top-0 left-0 w-full h-full object-cover"
            autoPlay
            preload="preload"
            muted
          >
            <source src="../assets/Video.mp4" type="video/mp4" />
          </video>
          <br />
        </>
      ) : (
        <img
          src="../assets/background.jpg"
          alt="Video"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      )}

      <div className="flex items-center">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 1.5 }}
          variants={{
            hidden: { opacity: 0, y: 100, z: -50 },
            visible: { opacity: 1, y: 0, z: 0 },
          }}
          className="flex flex-col justify-between items-center z-10"
        >
          <img src="../assets/Logo3.png" className="h-1vw" alt="logo" />
          <p className="text-3xl md:text-6xl font-opensans">Özgün Can Güler</p>
          <p className="text-md md:text-3xl font-opensans">
            Frontend Developer & UI/UX Designer
          </p>
        </motion.div>
      </div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 1.5 }}
        variants={{
          hidden: { opacity: 0, y: 25, z: -10 },
          visible: { opacity: 1, y: 0, z: 0 },
        }}
        className="flex flex-auto justfiy-between items-center gap-8 z-10"
      >
        <SocialMediaIcons />
      </motion.div>
    </section>
  );
};

export default Landing;
